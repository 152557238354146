<script>
import { mapState } from 'vuex';
import { Line } from 'vue-chartjs';
import { getFormattedCurrentDate } from '@/utils.js';

export default {
  extends: Line,
  props: {
    datasets: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      chartdata: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'index',
          intersect: false
        },
        hover: {
          mode: 'index',
          intersect: false,
          animationDuration: 0
        },
        scales: {
          yAxes: [
            {
              id: 'y-axis-1',
              display: true,
              type: 'linear',
              position: 'left',
              ticks: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: this.datasets[0].label
              }
            },
            {
              id: 'y-axis-2',
              display: this.datasets.length > 1,
              type: 'linear',
              position: 'right',
              gridLines: {
                drawTicks: false,
                display: false
              },
              ticks: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: this.datasets.length > 1 ? this.datasets[1].label : ''
              }
            }
          ],
          xAxes: [
            {
              id: 'x-axis-1',
              ticks: {
                display: true
              },
              gridLines: {
                drawTicks: true,
                display: true
              }
            }
          ]
        }
      }
    };
  },
  computed: {
    ...mapState(['table_chart'])
  },
  mounted() {
    const combinedLabels = [];
    const yAxisArray = [];

    if (this.datasets.length > 2) {
      const extractUnit = (label) => {
        const match = label.match(/\(([^)]+)\)/);

        return match ? match[1] : null;
      };

      // Raggruppa le label e ottieni gli indici
      const groupedLabels = this.datasets.reduce((acc, item, index) => {
        const unit = extractUnit(item.label);

        if (unit) {
          if (!acc[unit]) {
            acc[unit] = { labels: [], indices: [] };
          }

          acc[unit].labels.push(item.label);
          acc[unit].indices.push(index);
        }

        return acc;
      }, {});

      Object.values(groupedLabels).forEach((group, i) => {
        combinedLabels.push(group.labels.join(' / '));
        group.indices.forEach(() => {
          yAxisArray.push(`y-axis-${i + 1}`);
        });
      });

      this.options.scales.yAxes[0].scaleLabel.labelString = combinedLabels[0];
      this.options.scales.yAxes[1].scaleLabel.labelString = combinedLabels[1];
    } else {
      this.datasets.forEach((e, i) => yAxisArray.push(`y-axis-${i + 1}`));
    }

    [...this.table_chart.rasterVectorChartLabels] = this.table_chart.rasterVectorChartLabels.map(time => {
      const timeFormat = time.includes('T') ? getFormattedCurrentDate(new Date(time)) : time.replace('Z', '');

      return timeFormat.replace(/-/g, '/');
    });

    this.chartdata = {
      labels: this.table_chart.rasterVectorChartLabels,
      datasets: this.table_chart.rasterVectorChartDataSetLine.map((data, index) => {
        return {
          ...data,
          yAxisID: yAxisArray[index]
        };
      })
    };

    this.$emit('chart_data', this.chartdata);
    this.separateDaysPlugin();
    this.renderChart(this.chartdata, this.options);
  },
  methods: {
    separateDaysPlugin() {
      this.addPlugin({
        beforeDatasetsDraw: chart => {
          const ctx = chart.ctx;
          const xAxis = chart.scales['x-axis-1'];
          const yAxis = chart.scales['y-axis-1'];
          const tickGap = xAxis.getPixelForTick(1) - xAxis.getPixelForTick(0);

          ctx.save();
          let previousDate = null;
          let currentBackgroundColor = 'rgba(255, 255, 255, 0)';
          xAxis.ticks.forEach((tick, index) => {
            if (index === 0) {
              previousDate = tick.split(' ')[1];

              return;
            }

            const currentDate = tick.split(' ')[1];
            const sameDay = previousDate === currentDate;
            previousDate = currentDate;

            if (!sameDay) {
              currentBackgroundColor = currentBackgroundColor === 'rgba(255, 255, 255, 0)'
                ? 'rgba(230, 246, 236, 1)'
                : 'rgba(255, 255, 255, 0)';
            }

            const endTickGap = index === xAxis.ticks.length - 1 ? 0 : tickGap * 1;
            const startX = xAxis.getPixelForTick(index - 1) + tickGap;
            const endX = xAxis.getPixelForTick(index) + endTickGap;

            ctx.fillStyle = currentBackgroundColor;
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillRect(startX, yAxis.top, endX - startX, yAxis.bottom - yAxis.top);
          });
          ctx.restore();
        }
      });
    }
  }
};
</script>
